import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gabaritosn',
  templateUrl: './gabaritosn.component.html',
  styleUrls: ['./gabaritosn.component.scss']
})
export class GabaritosnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
