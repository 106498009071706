import { PacoteTxRx } from './../../shared/models/pacote-tx-rx.model';
import { Clientes} from '../../shared/models/clientes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  formData: Clientes;
  private readonly APIClientes = `${environment.API}${environment.BASE_URL}Cliente/`;
  pacotetxrx: PacoteTxRx;

  constructor(private http: HttpClient) { }

  postClientes() {

    const retrievedObject = localStorage.getItem('token');

    if (retrievedObject != null) {
      const objetoLogin = JSON.parse(retrievedObject);

      const Filtros = { IdInt: 1  };
      const pacoteTxRx = { Token: objetoLogin.Token,
                          EmpCodigo: objetoLogin.EmpCodigo,
                          AplCodigo: objetoLogin.Aplicacao,
                          LogId: objetoLogin.LogId,
                          Filtros: Filtros,
                          Cliente: this.formData };

      return this.http.post(this.APIClientes + 'CriarCliente/', JSON.stringify(pacoteTxRx))
      .toPromise()
      .then(res => this.pacotetxrx = res as PacoteTxRx);
    }
  }

  putClientes() {

    const retrievedObject = localStorage.getItem('token');

    if (retrievedObject != null) {
      const objetoLogin = JSON.parse(retrievedObject);

      const Filtros = { IdInt: 1  };
      const pacoteTxRx = { Token: objetoLogin.Token,
                          EmpCodigo: objetoLogin.EmpCodigo,
                          AplCodigo: objetoLogin.Aplicacao,
                          LogId: objetoLogin.LogId,
                          Filtros: Filtros,
                          Cliente: this.formData };

      return this.http.post(this.APIClientes + 'AtualizarCliente/', JSON.stringify(pacoteTxRx))
      .toPromise()
      .then(res => this.pacotetxrx = res as PacoteTxRx);
    }

   }

   deleteClientes(id) {

    const retrievedObject = localStorage.getItem('token');

    if (retrievedObject != null) {
      const objetoLogin = JSON.parse(retrievedObject);

      const Filtros = { IdInt: id  };
      const pacoteTxRx = { Token: objetoLogin.Token,
                          EmpCodigo: objetoLogin.EmpCodigo,
                          AplCodigo: objetoLogin.Aplicacao,
                          LogId: objetoLogin.LogId,
                          Filtros: Filtros
                           };

      return this.http.post(this.APIClientes + 'ExcluirCliente/', JSON.stringify(pacoteTxRx))
      .toPromise()
      .then(res => this.pacotetxrx = res as PacoteTxRx);
    }
   }

  refreshList() {

    const retrievedObject = localStorage.getItem('token');

    if (retrievedObject != null) {
      const objetoLogin = JSON.parse(retrievedObject);

      const Filtros = { IdInt: 1  };
      const pacoteTxRx = { Token: objetoLogin.Token,
                          EmpCodigo: objetoLogin.EmpCodigo,
                          AplCodigo: objetoLogin.Aplicacao,
                          LogId: objetoLogin.LogId,
                          Filtros: Filtros };

      return this.http.post(this.APIClientes + 'GetClientes/', JSON.stringify(pacoteTxRx))
      .toPromise()
      .then(res => this.pacotetxrx = res as PacoteTxRx);
  }
  }
}
