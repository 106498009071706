import { MenuModule } from './menu.module';
import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { Observable, empty, of, Subject, EMPTY } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';

import { map, tap, catchError } from 'rxjs/operators';
import { JSDocTagName } from '@angular/compiler/src/output/output_ast';
import { stringify } from '@angular/core/src/util';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  preserveWhitespaces: true
})
export class MenuComponent implements OnInit {

  menus$: Observable<MenuModule[]>;
  error$ = new Subject<Boolean>();

  constructor(
    private router: Router,
    private routerModule: RouterModule,
    private route: ActivatedRoute

  ) {}

  ngOnInit() {
    this.verificaMenu();
  }

  verificaMenu() {
    const retrievedObject = localStorage.getItem('token');
    console.log('retrievedObject: ', JSON.parse(retrievedObject));

    if (retrievedObject != null) {
      const objetoLogin = JSON.parse(retrievedObject);
      this.menus$ = objetoLogin.Paginas;
      /* console.log('Menusss' + JSON.stringify(this.menus$)); */
      return this.menus$;
    }
    // tslint:disable-next-line: one-line
    else {
      console.log('Usuário não possui acesso!');
      this.router.navigateByUrl('/gabaritohome');
    }
  }
}
