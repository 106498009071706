import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { tap, delay, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private readonly APILogin = `${environment.API}${environment.BASE_URL}Login/Login`;
  private readonly APILogout = `${environment.API}${environment.BASE_URL}Login/Logout`;
  constructor(private http: HttpClient) { }
  

  logar(login) {
    return this.http.post(this.APILogin, JSON.stringify(login));
    }

  logout(login)
  {
    return this.http.post(this.APILogout, JSON.stringify(login));
  }

  validatoken(token)
  {
    return true;
  }
}
