import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gabaritoministerio',
  templateUrl: './gabaritoministerio.component.html',
  styleUrls: ['./gabaritoministerio.component.scss']
})
export class GabaritoministerioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
