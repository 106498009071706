import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-gabaritohome',
  templateUrl: './gabaritohome.component.html',
  styles: [],
  styleUrls: ['./gabaritohome.component.scss'],
})
export class GabaritohomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
