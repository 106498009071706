
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { FormSid1Component } from './form-sid1/form-sid1.component';
import { ContatoComponent } from './contato/contato.component';
import { GabaritohomeComponent } from './gabaritohome/gabaritohome.component';
import { GabaritoprevidenciaComponent } from './gabaritoprevidencia/gabaritoprevidencia.component';
import { GabaritoreceitaComponent } from './gabaritoreceita/gabaritoreceita.component';
import { GabaritoministerioComponent } from './gabaritoministerio/gabaritoministerio.component';
import { GabaritocrcComponent } from './gabaritocrc/gabaritocrc.component';
import { GabaritosnComponent } from './gabaritosn/gabaritosn.component';
import { GabaritoempresaComponent } from './gabaritoempresa/gabaritoempresa.component';
import { GabaritoservicosComponent } from './gabaritoservicos/gabaritoservicos.component';
import { GabaritolinksComponent } from './gabaritolinks/gabaritolinks.component';
import { GabaritolocalizacaoComponent } from './gabaritolocalizacao/gabaritolocalizacao.component';
import { GabaritocaixaeconomicaComponent } from './gabaritocaixaeconomica/gabaritocaixaeconomica.component';

import { MenuComponent } from './menu/menu.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClientesDetalhesComponent } from './clientes/clientes-detalhes/clientes-detalhes.component';
import { ClientesListaComponent } from './clientes/clientes-lista/clientes-lista.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        
    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        FormSid1Component,
        //LoginComponent,
        ContatoComponent,
        GabaritohomeComponent,
        GabaritoprevidenciaComponent,
        GabaritoreceitaComponent,
        GabaritoministerioComponent,
        GabaritocrcComponent,
        GabaritosnComponent,
        GabaritoempresaComponent,
        GabaritoservicosComponent,
        GabaritolinksComponent,
        GabaritolocalizacaoComponent,
        GabaritocaixaeconomicaComponent,

        MenuComponent,
        ClientesComponent,
        ClientesDetalhesComponent,
        ClientesListaComponent,

    ]
})
export class PagesModule { }
