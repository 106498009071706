import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gabaritolinks',
  templateUrl: './gabaritolinks.component.html',
  styleUrls: ['./gabaritolinks.component.scss']
})
export class GabaritolinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
