import { ClientesService } from './clientes.service';
import { Component, OnInit } from '@angular/core';
import { Observable, empty, of, Subject, EMPTY } from 'rxjs';
import { MenuModule } from '../menu/menu.module';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  menus$: Observable<MenuModule[]>;
  constructor( private router: Router,
    private routerModule: RouterModule,
    private route: ActivatedRoute,
    private service: ClientesService) { }

  ngOnInit() {
    this.verificaAcessoPagina();
  }

  verificaAcessoPagina() {
    const retrievedObject = localStorage.getItem('token');
    console.log('retrievedObject: ', JSON.parse(retrievedObject));
    const pagina = 'clientes';

    if (retrievedObject != null) {
      const objetoLogin = JSON.parse(retrievedObject);
      this.menus$ = objetoLogin.Paginas;
      /* console.log('Menusss' + JSON.stringify(this.menus$)); */

      var temAcesso: Boolean = false;

      for (let i in this.menus$) {
        if (this.menus$[i].LogPaginaAngular == pagina) {
          temAcesso = true;
        }
      }
      if (!temAcesso) {
        console.log('Usuário não possui acesso a esta página!');
        this.router.navigateByUrl('/gabaritohome');
      }
    }
    else {
      console.log('Usuário não possui acesso!');
      this.router.navigateByUrl('/gabaritohome');
    }
  }

}
