import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule, provideRoutes, ROUTER_CONFIGURATION } from '@angular/router';
import { GabaritocaixaeconomicaComponent } from './pages/gabaritocaixaeconomica/gabaritocaixaeconomica.component';
import { GabaritocrcComponent } from './pages/gabaritocrc/gabaritocrc.component';
import { GabaritoempresaComponent } from './pages/gabaritoempresa/gabaritoempresa.component';
import { GabaritohomeComponent } from './pages/gabaritohome/gabaritohome.component';
import { GabaritolinksComponent } from './pages/gabaritolinks/gabaritolinks.component';
import { GabaritolocalizacaoComponent } from './pages/gabaritolocalizacao/gabaritolocalizacao.component';
import { GabaritoministerioComponent } from './pages/gabaritoministerio/gabaritoministerio.component';
import { GabaritoprevidenciaComponent } from './pages/gabaritoprevidencia/gabaritoprevidencia.component';
import { GabaritoreceitaComponent } from './pages/gabaritoreceita/gabaritoreceita.component';
import { GabaritoservicosComponent } from './pages/gabaritoservicos/gabaritoservicos.component';
import { GabaritosnComponent } from './pages/gabaritosn/gabaritosn.component';

import { ContatoComponent } from './pages/contato/contato.component';

import { LoginComponent } from './pages/login/login.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ClientesComponent } from './pages/clientes/clientes.component';

export { Routes } from '@angular/router';


const routes: Routes =[
    { path: '', redirectTo: 'gabaritohome', pathMatch: 'full' },
    { path: 'gabaritocaixaeconomica',          component: GabaritocaixaeconomicaComponent},
    { path: 'gabaritocrc',          component: GabaritocrcComponent},
    { path: 'gabaritoempresa',          component: GabaritoempresaComponent},
    { path: 'gabaritohome',          component: GabaritohomeComponent},
    { path: 'gabaritolinks',          component: GabaritolinksComponent},
    { path: 'gabaritolocalizacao',          component: GabaritolocalizacaoComponent},
    { path: 'gabaritoministerio',          component: GabaritoministerioComponent},
    { path: 'gabaritoprevidencia',          component: GabaritoprevidenciaComponent},
    { path: 'gabaritoreceita',          component: GabaritoreceitaComponent},
    { path: 'gabaritoservicos',          component: GabaritoservicosComponent},
    { path: 'gabaritosn',          component: GabaritosnComponent},

    { path: 'contato',          component: ContatoComponent},
    { path: 'login',            component: LoginComponent},
    { path: 'menu',            component: MenuComponent},
    { path: 'clientes',            component: ClientesComponent},
];

export const routesExp: Routes = [
  { path: 'menu',            component: MenuComponent},
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
