import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gabaritocrc',
  templateUrl: './gabaritocrc.component.html',
  styleUrls: ['./gabaritocrc.component.scss']
})
export class GabaritocrcComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
