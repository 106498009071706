import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gabaritoreceita',
  templateUrl: './gabaritoreceita.component.html',
  styleUrls: ['./gabaritoreceita.component.scss']
})
export class GabaritoreceitaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
