import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { tap, delay, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  private readonly APIEnviarEmail = `${environment.API}${environment.BASE_URL}Email/EnviarEmailContato`;
  constructor(private http: HttpClient) { }

  enviarEmail(email) {
    return this.http.post(this.APIEnviarEmail, JSON.stringify(email));
    }
}
