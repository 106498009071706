import { LoginService } from './login.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { BaseFormComponent } from './../../shared/base-form/base-form.component';
import { FormValidations } from './../../shared/form-validations';
import { map, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseFormComponent implements OnInit {

  formulario: FormGroup;
  dados: object;
  logado: boolean;

  test: Date = new Date();
  focus;
  focus1;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private service: LoginService,
    private location: Location,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {

    this.formulario = this.formBuilder.group({
      email: [null],
      senha: [null]
    });
    this.isLogado();
  }

  logout() {
    const _objLogin = JSON.parse(localStorage.getItem('token'));

    this.service.logout(_objLogin).subscribe(
      success => {
        localStorage.removeItem('token');
        this.logado = false;
      },
      error => console.log('Erro ao tentar logar na aplicação, tente novamente'),
      () => console.log('request completo')
    );

    this.router.navigateByUrl('/homeeles');
  }

  submit() {
    console.log(this.formulario.value);

    this.dados = new Object({
      Email: this.formulario.value['email'],
      Senha: this.formulario.value['senha'],
      Aplicacao: 1,
      EmpCodigo: 1
    });

    const valueSubmit = Object.assign({}, this.dados);

    this.service.logar(valueSubmit).subscribe(
      success => {
        console.log('sucesso');
        localStorage.setItem('token', JSON.stringify(success));
        this.logado = true;
        this.router.navigateByUrl('/menu');
      },
      error => console.log('Erro ao tentar logar na aplicação, tente novamente'),
      () => console.log('request completo')
    );
  }

  isLogado() {
    if (this.logado) {
      return true;
    }
    else {

      const _objLogin = JSON.parse(localStorage.getItem('token'));
      if (_objLogin != null) {
        this.logado = true;
        return true;
      }
      else {
        return false;
      }
    }
  }
}
