import { Cidade } from './../../../shared/models/cidade';
import { DropdownService } from './../../../shared/services/dropdown.service';

import { PacoteTxRx } from './../../../shared/models/pacote-tx-rx.model';
import { Clientes } from './../../../shared/models/clientes.model';
import { ClientesService } from './../clientes.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EstadoBr } from 'app/shared/models/estado-br.model';



@Component({
  selector: 'app-clientes-detalhes',
  templateUrl: './clientes-detalhes.component.html',
  styles: []
})
export class ClientesDetalhesComponent implements OnInit {

  estadoList: EstadoBr[];
  cidadeList: Cidade[];

  constructor(public service: ClientesService,
    private toastr: ToastrService,
    public dropdownservice: DropdownService,
    ) { }


  ngOnInit() {

    this.resetForm();
    this.dropdownservice.getAllCidades().subscribe(res  => this.cidadeList = res as Cidade[]);
    this.dropdownservice.getEstadosBr().subscribe(res => this.estadoList = res as EstadoBr[]);
}

resetForm(form?: NgForm) {

  if (form != null) {
    form.form.reset();
  }

  this.service.formData = {
    CLI_CODIGO: 0,
    CLI_DESCRICAO: '',
    CLI_ENDERECO: '',
    CLI_BAIRRO: '',
    CLI_CIDADE: '',
    CLI_ESTADO: '',
    CLI_CGC: '',
    CLI_CONTATO: '',
    CLI_CEP: '',
    CLI_INSCRICAO_ESTADUAL: '',
    CLI_TELEFONE: '',
    CLI_FAX: '',
    CLI_EMAIL: '',
    CLI_FISICA_JURIDICA: '',
    CLI_ENDERECO_ENTREGA: '',
    CLI_BAIRRO_ENTREGA: '',
    CLI_CIDADE_ENTREGA: '',
    CLI_ESTADO_ENTREGA: '',
    CLI_CGC_ENTREGA: '',
    CLI_CONTATO_ENTREGA: '',
    CLI_CEP_ENTREGA: '',
    CLI_TELEFONE_ENTREGA: '',
    CLI_FAX_ENTREGA: '',
    CLI_EMAIL_ENTREGA: '',
    CLI_ENDERECO_PAGAMENTO: '',
    CLI_BAIRRO_PAGAMENTO: '',
    CLI_CIDADE_PAGAMENTO: '',
    CLI_ESTADO_PAGAMENTO: '',
    CLI_CGC_PAGAMENTO: '',
    CLI_CONTATO_PAGAMENTO: '',
    CLI_CEP_PAGAMENTO: '',
    CLI_TELEFONE_PAGAMENTO: '',
    CLI_FAX_PAGAMENTO: '',
    CLI_EMAIL_PAGAMENTO: '',
    CLI_INSCRICAO_ESTADUAL_EN: '',
    CLI_INSCRICAO_ESTADUAL_PA: '',
    CLI_DEPARTAMENTO: '',
    CLI_DEPARTAMENTO_ENTREGA: '',
    CLI_DEPARTAMENTO_PAGAMENT: '',
    CLI_OBSERVACAO: '',
    CLI_VENDEDOR: '',
    CLI_TRANSPORTADORA: '',
    CLI_APELIDO: '',
    CLI_DATA_CADASTRO: '',
    CLI_RG: '',
    CLI_ORGAO_EMISSOR_RG: '',
    CLI_LIMITE_CREDITO: '',
    CLI_REGIAO: '',
    CLI_TIPO_EMPRESA: '',
    EMP_CODIGO: 0,
    CLI_CADASTRO_COMPLETO: 0,
    APL_CODIGO: 0,
    CLI_CODIGO_INDICANTE: 0,
    CLI_CMUN: '',
    CLI_CMUN_ENTREGA: '',
    CLI_CMUN_PAGAMENTO: '',
    CLI_CODUF: 0,
    CLI_CODUF_ENTREGA: 0,
    CLI_CODUF_PAGAMENTO: 0,
    CLI_PAIS: '',
    CLI_PAIS_ENTREGA: '',
    CLI_PAIS_PAGAMENTO: '',
    CLI_CPAIS: '',
    CLI_CPAIS_ENTREGA: '',
    CLI_CPAIS_PAGAMENTO: '',
    CLI_ISUF: '',
   };

}

onSubmit(form: NgForm) {
  
  //Desconsidero informação que não será alterada
  this.service.formData.CLI_DATA_CADASTRO = null;

  if (this.service.formData.CLI_CODIGO == 0) {
    this.insertRecord(form);
  } else {
    this.updateRecord(form);
  }
}

insertRecord(form: NgForm) {
  return this.service.postClientes()
  .then(
      res => {
      debugger;
      this.resetForm(form);
      this.toastr.success('Gravado com sucesso!', 'Cadastro de Clientes');
      this.service.refreshList();
    },
    err => {
      debugger;
      console.log(err);
      this.toastr.error('Desculpe, ocorreu um erro :' + err, 'Cadastro de Clientes');
      this.service.refreshList();
    }
  )
  .catch(
    err => {
      debugger;
      console.log(err);
      this.toastr.error('Desculpe, ocorreu um erro :' + err, 'Cadastro de Clientes');
      this.service.refreshList();
    }
  );

}

updateRecord(form: NgForm) {
  return this.service.putClientes()
  .then(
    res => {
    debugger;
    this.resetForm(form);
    this.toastr.info('Alterado com sucesso!', 'Cadastro de Clientes');
    this.service.refreshList();
  },
  err => {
    debugger;
    console.log(err);
    this.toastr.error('Desculpe, ocorreu um erro :' + err, 'Cadastro de Clientes');
    this.service.refreshList();
  }
)
.catch(
  err => {
    debugger;
    console.log(err);
    this.toastr.error('Desculpe, ocorreu um erro :' + err, 'Cadastro de Clientes');
    this.service.refreshList();
  }
);

}
}
