import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ContatoService } from './contato.service';
import 'rxjs/Rx';
import { from } from 'rxjs';
import { BaseFormComponent } from './../../shared/base-form/base-form.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styles: [],
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent extends BaseFormComponent implements OnInit {

  formulario: FormGroup;
  dados: object;

  constructor(
    private router: Router,
    private service: ContatoService,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastr: ToastrService

  ) {
    super();
  }

  focus;
  focus1;

  ngOnInit() {

    this.formulario = this.formBuilder.group({
      nome: [null],
      email: [null],
      mensagem: [null],
    });

  }

  submit() {
    console.log(this.formulario.value);


    const emailPadrao = new Object({
      Titulo: 'Mensagem formulário de contato do Site',
      SubTitulo: 'Nome do Visitante: ' + this.formulario.value['nome'] + ' Email do visitante: ' + this.formulario.value['email'],
      Mensagem: this.formulario.value['mensagem'],
      CodAplicacao: 6,
      EmpCodigo: 7,
      EmailDe: 'link@cpdvital.com.br',
      EmailPara: 'link@cpdvital.com.br',
      SistemaNome: 'Gabarito Contábil',
    });

    this.dados = new Object({
      EmpCodigo: 7,
      AplCodigo: 6,
      EmailPadrao: emailPadrao,
    });

    const valueSubmit = Object.assign({}, this.dados);

    this.service.enviarEmail(valueSubmit).subscribe(
      success => {
        console.log('sucesso');
        this.router.navigateByUrl('/gabaritohome');
      },
      error => {
        console.log('Erro ao tentar enviar email, tente novamente');
        this.toastr.success('Erro ao tentar enviar email, tente novamente!', 'Formulário de Contato');
      }
      ,
      () => {console.log('request completo');
      this.toastr.success('Email enviado com sucesso!', 'Formulário de Contato');
    }
    );
  }
}
