import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gabaritoempresa',
  templateUrl: './gabaritoempresa.component.html',
  styleUrls: ['./gabaritoempresa.component.scss']
})
export class GabaritoempresaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
