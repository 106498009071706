import { PacoteTxRx } from './../../../shared/models/pacote-tx-rx.model';
import { Clientes} from '../../../shared/models/clientes.model';
import { ClientesService } from './../clientes.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clientes-lista',
  templateUrl: './clientes-lista.component.html',
  styles: []
})
export class ClientesListaComponent implements OnInit {

  constructor(public service: ClientesService,
    private toastr: ToastrService,
    ) { }

  ngOnInit() {
    this.service.refreshList();
    console.log(this.service.formData);
  }

  populateForm(cl: Clientes) {
    this.service.formData = Object.assign({}, cl);

    var datestring = new Date(this.service.formData.CLI_DATA_CADASTRO),
    dia  = datestring.getDate().toString(),
    diaF = (dia.length == 1) ? '0'+ dia : dia,
    mes  = (datestring.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length == 1) ? '0'+ mes : mes,
    anoF = datestring.getFullYear();
    this.service.formData.CLI_DATA_CADASTRO = diaF + "/" + mesF + "/" + anoF;
  }

  onDelete(CLI_CODIGO) {

    if (confirm('Tem certeza que quer deletar o registro?')) {
      this.service.deleteClientes(CLI_CODIGO)
      .then(
        res => {
          this.service.refreshList();
          this.toastr.warning('Cliente excluído com sucesso', 'Cadastro de Clientes');
      },
      err => {
        debugger;
        console.log(err);
        this.toastr.error('Desculpe, ocorreu um erro ao excluir o Cliente, erro:' + err, 'Cadastro de Clientes');
        this.service.refreshList();
      }
    )
    .catch(
      err => {
        debugger;
        console.log(err);
        this.toastr.error('Desculpe, ocorreu um erro ao excluir o Cliente, erro:' + err, 'Cadastro de Clientes');
        this.service.refreshList();
      })
  }

  }
}
